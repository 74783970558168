
import * as Vue from "vue";
import { Popover, PopoverButton } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import MenuDataTools from './menus/MenuDataTools.vue';
import MenuEconomicTools from './menus/MenuEconomicTools.vue';
import MenuVersion from './menus/MenuVersion.vue';
import DocumentationMenu from './menus/DocumentationMenu.vue';
import Footer from './Footer.vue';
import SearchForm from './SearchForm.vue';

export default Vue.defineComponent({
  props: {
    showPadding: {
      type: Boolean,
      default: true,
    },
    navBgColor: {
      type: String,
    },
    isFixed: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    Popover, PopoverButton,
    ChevronDownIcon,
    MenuDataTools,
    MenuEconomicTools,
    MenuVersion,
    Footer,
    SearchForm,
    DocumentationMenu,
  },
  setup(props) {
    return {
      searchIsActive: Vue.ref(false),
      isDark: props.navBgColor ? true : false,
      backgroundColor: props.navBgColor ? props.navBgColor : '#FAFBFB',
    }
  },
  methods: {
    toggleSearch() {
      this.searchIsActive = !this.searchIsActive;
    },
    isSelected(name: string) {
      if (name === 'home' && this.$route.path === '/') {
        return true;
      } else if (name === 'docs' && this.$route.path.startsWith('/docs')) {
        return true;
      }
      return false;
    }
  }
});
