
import * as Vue from "vue";
import { Popover, PopoverButton } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import ActiveToolsOverlay from "@/components/ActiveToolsOverlay.vue";

const logos = [
  require('@/assets/homepage-logos/amazon.svg'),
  require('@/assets/homepage-logos/airbnb.svg'),
  require('@/assets/homepage-logos/alibaba.svg'),
  require('@/assets/homepage-logos/allrecipes.svg'),
  require('@/assets/homepage-logos/accuweather.svg'),
  require('@/assets/homepage-logos/autotrader.svg'),
  require('@/assets/homepage-logos/barnesnoble.svg'),
  require('@/assets/homepage-logos/bing.svg'),
  require('@/assets/homepage-logos/bloomberg.svg'),
  require('@/assets/homepage-logos/booking.svg'),
  require('@/assets/homepage-logos/buzzfeed.svg'),
  require('@/assets/homepage-logos/cbssports.svg'),
  require('@/assets/homepage-logos/cnn.svg'),
  require('@/assets/homepage-logos/craigslist.svg'),
  require('@/assets/homepage-logos/dribble.svg'),
  require('@/assets/homepage-logos/ebay.svg'),
  require('@/assets/homepage-logos/espn.svg'),
  require('@/assets/homepage-logos/etsy.svg'),
  require('@/assets/homepage-logos/eventbrite.svg'),
  require('@/assets/homepage-logos/expedia.svg'),
  require('@/assets/homepage-logos/facebook.svg'),
  require('@/assets/homepage-logos/fortune.svg'),
  require('@/assets/homepage-logos/glassdoor.svg'),
  require('@/assets/homepage-logos/google.svg'),
  require('@/assets/homepage-logos/hubspot.svg'),
  require('@/assets/homepage-logos/indeed.svg'),
  require('@/assets/homepage-logos/instagram.svg'),
  require('@/assets/homepage-logos/kayak.svg'),
  require('@/assets/homepage-logos/kickstarter.svg'),
  require('@/assets/homepage-logos/linkedin.svg'),
  require('@/assets/homepage-logos/medium.svg'),
  require('@/assets/homepage-logos/monster.svg'),
  require('@/assets/homepage-logos/motleyfool.svg'),
  require('@/assets/homepage-logos/nextdoor.svg'),
  require('@/assets/homepage-logos/indeed.svg'),
  require('@/assets/homepage-logos/priceline.svg'),
  require('@/assets/homepage-logos/quora.svg'),
  require('@/assets/homepage-logos/reddit.svg'),
  require('@/assets/homepage-logos/reuters.svg'),
  require('@/assets/homepage-logos/stackoverflow.svg'),
  require('@/assets/homepage-logos/ticketmaster.svg'),
  require('@/assets/homepage-logos/tumblr.svg'),
  require('@/assets/homepage-logos/twitter.svg'),
  require('@/assets/homepage-logos/upwork.svg'),
  require('@/assets/homepage-logos/walmart.svg'),
  require('@/assets/homepage-logos/wikipedia.svg'),
  require('@/assets/homepage-logos/yahoo.svg'),
  require('@/assets/homepage-logos/yelp.svg'),
  require('@/assets/homepage-logos/youtube.svg'),
  require('@/assets/homepage-logos/zillow.svg'),
  require('@/assets/homepage-logos/booking.svg'),
  require('@/assets/homepage-logos/allrecipes.svg'),
  require('@/assets/homepage-logos/linkedin.svg'),
]

const rows = [
  5,
  5,
  4,
  4,
  3,
  4,
  3,
  4,
  3,
  4,
  4,
  5,
  5,
];

export default Vue.defineComponent({
  components: {
    Popover,
    PopoverButton,
    ChevronDownIcon,
    ActiveToolsOverlay
},
  setup() {
    return {
      logos,
      rows,
    }
  },
  methods: {
    getLogo(rowIndex: number, itemIndex: number) {
      let count = 0;
      for (let i = 0; i < rowIndex; i++) {
        if (i === rowIndex) break;
        count += rows[i];
      }
      return logos[count + itemIndex - 1];
    }
  }
});
