
import * as Vue from "vue";
import { PopoverPanel } from '@headlessui/vue'
import NavItem from '../layouts/NavItem.vue';

export default Vue.defineComponent({
  props: {
    subPath: {
      default: '',
    }
  },
  components: {
    PopoverPanel,
    NavItem
  }
});
