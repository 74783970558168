
import * as Vue from "vue";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

export default Vue.defineComponent({
  components: {
    Popover, PopoverButton, PopoverPanel,
    ChevronDownIcon,
  }
});
