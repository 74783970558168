import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, Suspense as _Suspense, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }))
    ]),
    _: 1
  }))
}